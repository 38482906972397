import React from 'react';
import Directorsfeed from '../components/directorsfeed';
import Layout from '../components/layout';
import SEO from '../components/seo';
import SubpageHero from '../components/subpage-hero';

const TeamPage = (props) => {
    return (
    <Layout>
       <SEO title="Team" />
    
        <SubpageHero title="Team" />

        <div className="hub-wrapper">

            <div className="hub-section">         

                <div className="hub-cards-section hide-contenttype">

                    <Directorsfeed />

                </div>

            </div>

            <div className="topline-statement">
                <p>We are grateful to Julia Ritz Toffoli, our founding Director of Whiskey, for her efforts to launch the Museum of Distilled Spirits and wish her the best of drams always.</p>
            </div>   

        </div>

  </Layout>
)
}

export default TeamPage

/*;


export const query = graphql`
 query StandardPageTemplate($id: String!) {

    contentfulStandardPage(id: {eq: $id}) {
        id
        slug
        title
        pageContent {
          childMarkdownRemark {
            html
          }
        }
      }
 }
`
*/